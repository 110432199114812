<template>
  <div class="order-page-content">
    <Empty photo="3" label="暂无工单信息" v-if="total == 0"/>
    <div class="order-list" v-else>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadData"
      >
        <div class="order-card" v-for="(item, index) in list" :key="index">
          <router-link :to="'/order/detail/' + item.id" class="order-link">
            <div class="order-card__hd">
              <div class="title">
                <div class="order-tag flex-center" :class="{'orange': item.type == 5, 'blue': item.type != 5}">{{ item.type_title }}</div>
                <div class="order-name van-ellipsis">{{ item.product_category }}</div>
              </div>
              <div class="status">已提交</div>
            </div>
            <div class="order-card__bd">
              <div class="photo">
                <img :src="item.product_photo" class="contain-img" alt="">
              </div>
              <div class="info">
                <!-- 送货，保养，清洗， -->
                <template v-if="item.type == 1 || item.type == 8 || item.type == 6 || item.type == 7 || item.type == 9 || item.type == 10">
                  <div class="info-item">产品型号：{{ item.product_title }}</div>
                  <!-- <div class="info-item">服务时间：{{ item.appoint_time }}</div> -->
                  <div class="info-item">工单单号：{{ item.order_sn }}</div>
                </template>
                <!-- KDS，安装，前置 -->
                <template v-else-if="item.type == 2 || item.type == 3 || item.type == 4">
                  <div class="info-item" v-if="item.logistics_status == 1">物流状态：已到货</div>
                  <div class="info-item" v-else-if="item.logistics_status == 2">物流状态：未到货</div>
                  <div class="info-item" v-else>物流状态：</div>
                  <div class="info-item">产品型号：{{ item.product_title || '型号暂未填写' }}</div>
                  <!-- <div class="info-item">服务时间：{{ item.appoint_time }}</div> -->
                  <div class="info-item">工单单号：{{ item.order_sn }}</div>
                </template>
                <!-- 维修 -->
                <template v-else-if="item.type == 5">
                  <div class="info-item">故障类型：{{ item.gz_title }}</div>
                  <div class="info-item">产品型号：{{ item.product_title || '型号暂未填写' }}</div>
                  <div class="info-item">工单单号：{{ item.order_sn }}</div>
                </template>
              </div>
            </div>
          </router-link>
          <div class="order-card__ft van-hairline--top">
            <template v-if="item.is_sync == 0">
              <div class="order-card__btn flex-center van-hairline--surround" @click="onRemindOrder(item)" v-if="item.status == 1">
                <span>催单</span>
              </div>
              <div class="order-card__btn flex-center van-hairline--surround" @click.stop="showTimePopup(item)" v-if="item.status == 1">
                <span>改约</span>
              </div>
            </template>
            
            <div class="order-card__btn flex-center van-hairline--surround" @click.stop="onCancel(item.id)">
              <span>取消服务</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <MCancel 
      :id="editId" 
      :visible="cancelVisible" 
      @close="closeCancel" 
      @cancel="onCancelOrder"/>
    <ServiceTimePopup 
      ref="servTime"
      :visible="timeVisible" 
      :tid="editId"
      @close="closeTimePopup" 
      @submit="choiceTimeSuccess"
    />
  </div>
</template>

<script>
  import Empty from '@/components/empty'
  import { getOrderList, remindOrder } from '@/api/order'
  import { List, Toast, Dialog } from 'vant'
  import MCancel from '../detail/components/cancel.vue'
  import ServiceTimePopup from '@/components/ServiceTimePopup'

  export default {
    components: {
      [List.name]: List,
      Empty,
      MCancel,
      ServiceTimePopup
    },
    data() {
      return {
        editId: -1,
        editItem: null,
        list: [],
        loading: false,
        finished: false,
        total: -1,
        page: 1,
        pageSize: 10,
        cancelVisible: false,
        timeVisible: false
      }
    },
    methods: {
      loadData(type) {
        if (type == 'init') {
          this.list = []
          this.loading = true
          this.finished = false
          this.page = 1
        }
        const params = {
          page: this.page,
          limit: this.pageSize,
          status: 1
        }
        getOrderList(params).then(res => {
          this.list = this.list.concat(res.data.list)
          this.total = res.data.total
          this.loading = false
          this.page ++
          if (this.list.length >= this.total) {
            this.finished = true
          }
        })
      },
      onCancel(id) {
        this.editId = id
        this.$nextTick(() => {
          this.cancelVisible = true
        })
      },
      closeCancel() {
        this.editId = -1
        this.cancelVisible = false
      },
      onCancelOrder() {
        Toast.success('取消订单成功')
        this.cancelVisible = false
        this.loadData('init')
      },
      /**
       * 催单
       */
      onRemindOrder(info) {
        const spTime = info.appoint_time.split(' ')
        // const time = spTime[1].split('-')
        const startTime = new Date(spTime[0].replace(/-/g, '/') + ' 00:00' ).getTime()
        const now = Date.now()
        if (startTime > now) {
          Dialog.alert({
            title: '催促工单',
            message: `您的服务预约在${ info.appoint_time }，请耐心等待，服务工程师会在上门前与您联系`,
            confirmButtonColor: '#186AFF'
          })
          return
        }
        const toast = Toast.loading({
          message: '处理中...',
          forbidClick: true,
        })
        remindOrder({id: info.id}).then(() => {
          toast.clear()
          Dialog.alert({
            title: '催促工单',
            message: '服务工单已催促，请耐心等待',
            confirmButtonColor: '#186AFF'
          })
        })
      },
      showTimePopup(item) {
        this.editId = item.id
        this.$refs.servTime.setTime(item.appoint_time)
        this.timeVisible = true
      },
      closeTimePopup() {
        this.editId = -1
        this.timeVisible = false
      },
      choiceTimeSuccess() {
        this.closeTimePopup()
        this.loadData('init')
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>